define('felixakkermans/components/nav-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),

    tagName: 'header',
    classNames: ['fbf-app-bar fbf-app-bar--transparent']
  });
});