define('felixakkermans/components/fbf-progress-bar', ['exports', '@feedbackfruits/styles/components/fbf-progress-bar', 'felixakkermans/config/environment'], function (exports, _fbfProgressBar, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var environment = _environment.default.environment;
  exports.default = _fbfProgressBar.default.extend({
    isTesting: environment === 'test'
  });
});