define('felixakkermans/components/fbf-well', ['exports', '@feedbackfruits/styles/components/fbf-well'], function (exports, _fbfWell) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfWell.default;
    }
  });
});