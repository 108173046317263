define('felixakkermans/components/fbf-search-field-toggle', ['exports', '@feedbackfruits/styles/components/fbf-search-field-toggle', 'felixakkermans/config/environment'], function (exports, _fbfSearchFieldToggle, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var environment = _environment.default.environment;
  exports.default = _fbfSearchFieldToggle.default.extend({
    setValidationDelay: Ember.on('init', function () {
      if (environment === 'test') {
        this.set('validationDelay', 10);
      }
    })
  });
});