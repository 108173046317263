define('felixakkermans/components/fbf-data-table-actionable-cell', ['exports', '@feedbackfruits/styles/components/fbf-data-table-actionable-cell'], function (exports, _fbfDataTableActionableCell) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfDataTableActionableCell.default;
    }
  });
});