define('felixakkermans/components/fbf-item-picker', ['exports', '@feedbackfruits/styles/components/fbf-item-picker'], function (exports, _fbfItemPicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfItemPicker.default;
    }
  });
});