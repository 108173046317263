define('felixakkermans/components/fbf-menu-button-toggle', ['exports', '@feedbackfruits/styles/components/fbf-menu-button-toggle'], function (exports, _fbfMenuButtonToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfMenuButtonToggle.default;
    }
  });
});