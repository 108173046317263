define('felixakkermans/components/fbf-menu-divider', ['exports', '@feedbackfruits/styles/components/fbf-menu-divider'], function (exports, _fbfMenuDivider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfMenuDivider.default;
    }
  });
});