define('felixakkermans/components/fbf-swipe', ['exports', '@feedbackfruits/styles/components/fbf-swipe'], function (exports, _fbfSwipe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfSwipe.default;
    }
  });
});