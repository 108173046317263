define('felixakkermans/components/fbf-switch', ['exports', '@feedbackfruits/styles/components/fbf-switch'], function (exports, _fbfSwitch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfSwitch.default;
    }
  });
});