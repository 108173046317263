define('felixakkermans/components/fbf-data-table-title', ['exports', '@feedbackfruits/styles/components/fbf-data-table-title'], function (exports, _fbfDataTableTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfDataTableTitle.default;
    }
  });
});