define('felixakkermans/components/fbf-menu-href-list-item', ['exports', '@feedbackfruits/styles/components/fbf-menu-href-list-item'], function (exports, _fbfMenuHrefListItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfMenuHrefListItem.default;
    }
  });
});