define('felixakkermans/services/responsive', ['exports', '@feedbackfruits/styles/services/responsive'], function (exports, _responsive) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _responsive.default;
    }
  });
});