define('felixakkermans/components/fbf-bottom-sheet', ['exports', '@feedbackfruits/styles/components/fbf-bottom-sheet'], function (exports, _fbfBottomSheet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfBottomSheet.default;
    }
  });
});