define('felixakkermans/components/fbf-path-direction', ['exports', '@feedbackfruits/styles/components/fbf-path-direction'], function (exports, _fbfPathDirection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfPathDirection.default;
    }
  });
});