define('felixakkermans/components/mdl-mini-footer', ['exports', 'ember-material-lite/components/mdl-mini-footer'], function (exports, _mdlMiniFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mdlMiniFooter.default;
    }
  });
});