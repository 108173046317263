define('felixakkermans/components/mdl-tabs', ['exports', 'ember-material-lite/components/mdl-tabs'], function (exports, _mdlTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mdlTabs.default;
    }
  });
});