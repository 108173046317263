define('felixakkermans/components/fbf-fullscreen-controls', ['exports', '@feedbackfruits/styles/components/fbf-fullscreen-controls', 'felixakkermans/config/environment', 'felixakkermans/utils/timeout'], function (exports, _fbfFullscreenControls, _environment, _timeout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var environment = _environment.default.environment;
  exports.default = _fbfFullscreenControls.default.extend({
    init: function init() {
      if (environment === 'test') {
        this.set('hideDelay', (0, _timeout.default)(this.hideDelay));
      }

      this._super.apply(this, arguments);
    }
  });
});