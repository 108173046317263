define('felixakkermans/components/fbf-focus-first', ['exports', '@feedbackfruits/styles/components/fbf-focus-first'], function (exports, _fbfFocusFirst) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfFocusFirst.default;
    }
  });
});