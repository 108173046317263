define('felixakkermans/components/fbf-slide-bar', ['exports', '@feedbackfruits/styles/components/fbf-slide-bar'], function (exports, _fbfSlideBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfSlideBar.default;
    }
  });
});