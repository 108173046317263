define('felixakkermans/components/fbf-datepicker', ['exports', '@feedbackfruits/styles/components/fbf-datepicker', 'felixakkermans/config/environment'], function (exports, _fbfDatepicker, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var environment = _environment.default.environment;
  exports.default = _fbfDatepicker.default.extend({
    setValidationDelay: Ember.on('init', function () {
      if (environment === 'test') {
        this.set('validationDelay', 10);
      }
    }),

    forceNoMinDate: environment === 'test'
  });
});