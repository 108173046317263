define('felixakkermans/components/fbf-option-list', ['exports', '@feedbackfruits/styles/components/fbf-option-list'], function (exports, _fbfOptionList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfOptionList.default;
    }
  });
});