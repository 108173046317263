define('felixakkermans/components/fbf-meta-text', ['exports', '@feedbackfruits/styles/components/fbf-meta-text'], function (exports, _fbfMetaText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfMetaText.default;
    }
  });
});