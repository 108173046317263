define('felixakkermans/components/fbf-linear-bar-average', ['exports', '@feedbackfruits/styles/components/fbf-linear-bar-average'], function (exports, _fbfLinearBarAverage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfLinearBarAverage.default;
    }
  });
});