define('felixakkermans/services/shortcuts', ['exports', '@feedbackfruits/styles/services/shortcuts'], function (exports, _shortcuts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _shortcuts.default;
    }
  });
});