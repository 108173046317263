define('felixakkermans/blueprints/ember-material-lite', ['exports', 'ember-material-lite/blueprints/ember-material-lite'], function (exports, _emberMaterialLite) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberMaterialLite.default;
    }
  });
});