define('felixakkermans/components/fbf-data-table-matrix-header', ['exports', '@feedbackfruits/styles/components/fbf-data-table-matrix-header'], function (exports, _fbfDataTableMatrixHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfDataTableMatrixHeader.default;
    }
  });
});