define('felixakkermans/components/fbf-page-stepper-step', ['exports', '@feedbackfruits/styles/components/fbf-page-stepper-step'], function (exports, _fbfPageStepperStep) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfPageStepperStep.default;
    }
  });
});