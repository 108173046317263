define('felixakkermans/components/fbf-slide-bar-add', ['exports', '@feedbackfruits/styles/components/fbf-slide-bar-add'], function (exports, _fbfSlideBarAdd) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fbfSlideBarAdd.default;
    }
  });
});