define('felixakkermans/services/browser', ['exports', '@feedbackfruits/styles/services/browser'], function (exports, _browser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _browser.default;
    }
  });
});